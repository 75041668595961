<template>
    <div class="slamDunk-box">
        <img :src="acceptData.adv_bottom_img" v-if="screen_part_type != 0"
            style="position: absolute;top:0;bottom:0;left:0;right:0;width: 100%;height: 100%;" />
        <div :style="{ 'width': wrapWidth, 'height': wrapHeight }"
            :class="{ 'hidden': screen_part_type == 0, 'threePart': heng == 'h' && screen_part_type == 2, 'twoPart': heng == 'h' && screen_part_type == 3, 'threePartV': heng == 'v' && screen_part_type == 2, 'twoPartV': heng == 'v' && screen_part_type == 3 }">
            <iframe :src="frameSrc" frameborder="0" width="100%" height="100%"></iframe>
        </div>
    </div>
</template>
<script >
import { tenActiveDetailCollec } from "@/api/littleGameOne.js";
export default {
    data() {
        return {
            uniq: "", // 活动id
            coll_id: "", // 合集id
            frameSrc: "", // 游戏页面地址
            status: "",
            acceptData: '',
            wrapWidth: '',
            wrapHeight: '',
            screen_part_type: '',
            heng: '',
        };
    },
    mounted() {
        this.coll_id = this.$route.params.id;
        this.getDetail();
        setInterval(async () => {
            await this.getDetail();
        }, 7000);

    },
    methods: {
      
        getDetail() {
            tenActiveDetailCollec({
                colle_uniq: this.coll_id,
            }).then((res) => {
                if (res.status == 200) {
                    const nowTime = new Date().getTime();
                    this.acceptData = res.data
                    this.screen_part_type = this.acceptData.screen_part_type
                    this.frameSrc = this.acceptData.page_path
                    let w = 0
                    let h = 0
                    if (this.acceptData.screen_type == 9) {
                        w = 1920
                        h = 560
                    } else if (this.acceptData.screen_type == 8) {
                        w = 1920
                        h = 360
                    } else if (this.acceptData.screen_type == 7) {
                        w = 1920
                        h = 640
                    } else if (this.acceptData.screen_type == 6) {
                        w = 1920
                        h = 1080
                    } else if (this.acceptData.screen_type == 5) {
                        w = 1600
                        h = 1200
                    } else if (this.acceptData.screen_type == 4) {
                        w = 1400
                        h = 1400
                    } else if (this.acceptData.screen_type == 3) {
                        w = 1000
                        h = 1500
                    } else if (this.acceptData.screen_type == 2) {
                        w = 1000
                        h = 2000
                    } else if (this.acceptData.screen_type == 1) {//自定义
                        w = this.acceptData.screen_width
                        h = this.acceptData.screen_length
                    } else if (this.acceptData.screen_type == 0) {//默认自适应
                        w = 1920
                        h = 1080
                        this.screen_part_type = 0

                    }


                    //横屏
                    if (16 / 9 < w / h) {
                        this.heng = 'h'
                        if (this.acceptData.screen_type == 0) {
                            this.wrapHeight = '100%'
                            this.wrapWidth = '100%'
                        } else {
                            this.wrapHeight = '100%'
                            this.wrapWidth = 16 / 9 * h + 'px'
                            
                        }

                    } else {
                        //竖屏
                        this.heng = 'v'

                        if (this.acceptData.screen_type == 0) {
                            this.wrapHeight = '100%'
                            this.wrapWidth = '100%'
                        } else {
                            this.wrapWidth = w
                            this.wrapHeight = 9 / 16 * w + 'px'
                        }


                    }

                }
            });
        },

    },
};

</script>
<style scoped>
.hidden {
    overflow: hidden;
}

.slamDunk-box {
    width: 100%;
    height: 100%;
}

.threePart {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.twoPart {
    position: absolute;
    left: 0;
}

.threePartV {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
}

.twoPartV {
    position: absolute;
    bottom: 0;
}
</style>