import http from '../utils/http';

export const tenActiveDetail = (params) => http.post('/jiejing/h5/activity-info', params);

export const tenActiveDetailCollec = (params) => http.post('/jiejing/h5/collec-info', params);

export const tenUserOffset = (params) => http.post('/jiejing/h5/game-user-list', params);

export const tenTopList = (params) => http.post('/jiejing/h5/game-top-list', params);

export const getOverView = (params) => http.post('/jiejing/h5/get-over-view', params);

export const getuniq = (params) => http.post('/jiejing/h5/current-index', params); //获取 index页面图片
//查看驻留人数
// export const getflowPass = (params) => http.post('/jiejing/h5/get-flow-pass', params);

// 红包雨
export const getPrizeNum = (params) => http.get('/jiejing/h5/get-prize-num', params);

export const getTopUserList = (params) => http.get('/jiejing/h5/redpackage/get-top-user-list', params);

export const getHistoryPrizeUserList = (params) => http.get('/jiejing/h5/redpackage/get-history-prize-user-list', params);

export const getLastUserList = (params) => http.get('/jiejing/h5/redpackage/get-latest-user-list', params);

export const getPrizeList = (params) => http.get('/jiejing/h5/get-prize-list', params);

//答题活动
export const getAnswerOne = (params) => http.get('/jiejing/h5/answer/get-one', params);

export const getUserList = (params) => http.get('/jiejing/h5/answer/get-user-list', params);

//博饼
export const getLatestList = (params) => http.get('/jiejing/h5/bobing/get-latest-list', params);

export const getTopPrizeUserList = (params) => http.get('/jiejing/h5/bobing/get-top-prize-user-list', params);

export const getPrizeNumList = (params) => http.get('/jiejing/h5/bobing/get-prize-num-list', params);


//翻牌
export const getLatestListCard = (params) => http.get('/jiejing/h5/card/get-latest-list', params);

export const getTopPrizeUserListCard = (params) => http.get('/jiejing/h5/card/get-top-prize-user-list', params);

export const getTopPointsUserList = (params) => http.get('/jiejing/h5/card/get-top-points-user-list', params);

//剩余奖品数量列表弹框 (取前 5 个)
export const getPrizeNumListCard = (params) => http.get('/jiejing/h5/get-prize-num-list', params);
//获取最新中奖
export const getPrizeNewLst = (params) => http.get('/jiejing/h5/card/get-latest-prize-list', params);

//飞刀接口
export const getLatestListDao = (params) => http.get('/jiejing/h5/dao/get-latest-list', params);
//获取最新中奖
export const getPrizeNewLstdao = (params) => http.get('/jiejing/h5/dao/get-latest-prize-list', params);
//获取前十中奖用户
export const getTopPrizeUserListDao = (params) => http.get('/jiejing/h5/dao/get-top-prize-user-list', params);
export const getTopPointsUserListDao = (params) => http.get('/jiejing/h5/dao/get-top-points-user-list', params);

export const getTopPrizeUserListcar = (params) => http.get('/jiejing/h5/car/get-top-prize-user-list', params);
export const getTopPointsUserLiscar = (params) => http.get('/jiejing/h5/car/get-top-points-user-list', params);

export const getTopPrizeUserListzoo = (params) => http.get('/jiejing/h5/zoo/get-top-prize-user-list', params);
export const getTopPointsUserLiszoo = (params) => http.get('/jiejing/h5/zoo/get-top-points-user-list', params);
//拼图大挑战-大屏
//最新参与列表
export const getLatestListping = (params) => http.get('/jiejing/h5/jigsaw/get-latest-list', params);
//获取最新中奖
export const getPrizeNewLstping = (params) => http.get('/jiejing/h5/jigsaw/get-latest-prize-list', params);
//获取前十中奖用户
export const getTopPrizeUserListping = (params) => http.get('/jiejing/h5/jigsaw/get-top-prize-user-list', params);
//获取最佳玩家
export const getTopPointsUserListping = (params) => http.get('/jiejing/h5/jigsaw/get-top-points-user-list', params);

// 卡牌猜猜
//获取前十中奖用户
export const getTopPrizeUserListcardguess = (params) => http.get('/jiejing/h5/cardguess/get-top-prize-user-list', params);
//获取最新中奖
export const getPrizeNewLstcardguess = (params) => http.get('/jiejing/h5/cardguess/get-latest-prize-list', params);
//获取最新参与
export const getLatestListcardguess = (params) => http.get('/jiejing/h5/cardguess/get-latest-list', params);
//获取最佳玩家
export const getTopPointsUserListcardguess = (params) => http.get('/jiejing/h5/cardguess/get-top-points-user-list', params);

// 货柜消消乐
//获取前十中奖用户
export const getTopPrizeUserListlele = (params) => http.get('/jiejing/h5/xxl/get-top-prize-user-list', params);
//获取最新中奖
export const getPrizeNewLstlele = (params) => http.get('/jiejing/h5/xxl/get-latest-prize-list', params);
//获取最新参与
export const getLatestListlele = (params) => http.get('/jiejing/h5/xxl/get-latest-list', params);
//获取最佳玩家
export const getTopPointsUserListlele = (params) => http.get('/jiejing/h5/xxl/get-top-points-user-list', params);


// 保卫红包
//获取前十中奖用户
export const getTopPrizeUserListhongbao = (params) => http.get('/jiejing/h5/defend-red/get-top-prize-user-list', params);
//获取最新中奖
export const getPrizeNewLstlhongbao = (params) => http.get('/jiejing/h5/defend-red/get-latest-prize-list', params);
//获取最新参与
export const getLatestListhongbao = (params) => http.get('/iejing/h5/defend-red/get-latest-list', params);
//获取最佳玩家
export const getTopPointsUserListhongbao= (params) => http.get('/jiejing/h5/defend-red/get-top-points-user-list', params);

//弹幕上墙幸运抽奖

//获取前十中奖用户
export const getTopPrizeUserListliu = (params) => http.get('/jiejing/h5/msg/get-top-prize-user-list', params);

//获取留言列表
export const lstmsgLiu = (params) => http.get('/jiejing/h5/msg/lst-msg', params);

//获取留言列表
export const getPrizeNewLstLiu = (params) => http.get('/jiejing/h5/msg/get-latest-prize-list', params);





